import React, {useEffect, useRef} from 'react';
import {createPortal} from 'react-dom';
import styled from '@emotion/styled';
import Button from './Button';
import useKeypress from './useKeypress';
import {BACKGROUND_COLOR, BASE_COLOR, opacify} from './constants';

export interface Props {
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  onClose: () => void;
}

const Outer = styled.div`
  font-size: 0.7rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${opacify(BACKGROUND_COLOR, 0.85)};
  opacity: 0;
  transition: opacity 0.2s;

  &.visible {
    opacity: 1;
  }

  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  z-index: 9999;

  h2 {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
  }

  h3 {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
  }
`;

const ModalDiv = styled.div`
  position: relative;
  background: #fff;
  border: 1px solid ${opacify(BASE_COLOR, 0.15)};
  box-shadow: 0 0 2px ${opacify(BASE_COLOR, 0.25)};
  border-radius: 5px;
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 12pt;
  border: none;
  z-index: 2;
`;

const Modal: React.FC<Props> = (props) => {
  const {children,width,height,maxWidth,maxHeight} = props;
  const ref = useRef<HTMLDivElement>(null);
  useKeypress('Escape', props.onClose);
  const handleClose = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    props.onClose();
  };

  useEffect(() => {
    // https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
    const {scrollY} = window;
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    document.body.style.height = '100%';
    document.body.style.top = `-${scrollY}px`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.height = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  });
  return createPortal(
    <Outer
      className="visible"
      onClick={handleClose}
    >
      <ModalDiv
        ref={ref}
        onClick={(evt: React.MouseEvent) => evt.stopPropagation()}
        style={{
          width,
          height,
          maxWidth,
          maxHeight,
        }}
      >
        <CloseButton onClick={handleClose}>╳</CloseButton>
        {children}
      </ModalDiv>
    </Outer>,
    document.body
  );
};

export default Modal;

