import styled from '@emotion/styled';
import {BASE_COLOR} from './constants';


const Button = styled.button`
  cursor: pointer;
  flex-grow: 0;
  display: inline-block;
  padding: 2px 4px;
  opacity: 0.8;
  // transition: opacity 0.2s;
  // &:hover { opacity: 1; }
  border: 1px solid ${BASE_COLOR};
  border-radius: 3px;
  color: ${BASE_COLOR};
  font-size: 0.7rem;
  //font-family: 'Noto Sans', sans-serif;
  margin-bottom: 3px;
  background-color: #fff;
  transition: color 0.2s, background-color 0.2s;
  path {
    transition: fill 0.2s, stroke 0.2s;
  }
  &:hover {
    color: #fff;
    path {
      fill: #fff;
      stroke: #fff;
    }
    background-color: ${BASE_COLOR};
  }
`;

export default Button;