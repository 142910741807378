import { useEffect } from 'react';
/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 */
export default function useKeypress(key: string, onKeypress: () => void, stopPropagation?: boolean) {
  useEffect(() => {
    function onKeyup(e: KeyboardEvent) {
      if (stopPropagation) e.stopPropagation();
      if (e.key === key) onKeypress();
    }
    window.addEventListener('keydown', onKeyup);
    return () => window.removeEventListener('keydown', onKeyup);
  }, []);
}
