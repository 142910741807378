import styled from '@emotion/styled';
import {csvParse, csvParseRows} from 'd3-dsv';
import {viewport} from '@mapbox/geo-viewport';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import './App.css';
import useFetch from 'react-fetch-hook';
import NoScrollContainer from './NoScrollContainer';
import {FeatureCollection} from 'geojson';
import MapContainer from './MapContainer';
import {DeckGL} from '@deck.gl/react';
import {MapController, MapView} from '@deck.gl/core';
import {_MapContext as MapContext, StaticMap, ViewState, ViewStateChangeInfo,} from 'react-map-gl';
import {GeoJsonLayer, ScatterplotLayer} from '@deck.gl/layers';
import {descending, max} from 'd3-array';
import {scaleSqrt} from 'd3-scale';
import {Topology} from 'topojson-specification';
import {feature} from 'topojson-client';
import LabelsOverlay from './LabelsOverlay';
import {useWindowSize} from './useWindowSize';
import Flatbush from 'flatbush';
import WebMercatorViewport from 'viewport-mercator-project';
import {usePopper} from 'react-popper';
import {createPortal} from 'react-dom';
import {SpinningCircles} from './SpinningCircles';
import fullscreenIcon from './fullscreen.svg';
import Modal from './Modal';
import List from './List';
import {
  BACKGROUND_COLOR,
  BASE_COLOR,
  CIRCLE_FILL_COLOR,
  CIRCLE_LINE_COLOR,
  CIRCLE_LINE_COLOR__HIGHLIGHTED, colorAsRgba,
  LIST_RECORD_COLUMNS,
  opacify,
  SHAPE_FILL_COLOR,
  SHAPE_LINE_COLOR, TEXT_COLOR
} from './constants';
import { timeFormat, timeParse } from 'd3-time-format';
import Button from './Button';

const accessToken = process.env.REACT_APP_MapboxAccessToken;
const mapboxMapStyle = process.env.REACT_APP_StyleUrl;

// TODO проекция
// https://github.com/Kreozot/russian-geo-data/blob/master/map.svg

const SHOW_BASE_MAP = false;
const DARK_MODE = false;
export const MIN_ZOOM_LEVEL = 0;
export const MAX_ZOOM_LEVEL = 20;
export const MIN_PITCH = 0;
export const MAX_PITCH = +60;

const DATA_URL =
  // '/list.csv';
  'https://jupyter.ovdinfo.org/public/map_navalny_repressions.csv';
const CITIES_URL = '/cities.csv';


const Outer = styled(NoScrollContainer)({
  display: 'flex',
  flexDirection: 'column',
  color: TEXT_COLOR,
});

const FullscreenButton = styled.a`
  border: 1px solid ${opacify(BASE_COLOR, 0.15)};
  box-shadow: 0 0 2px ${opacify(BASE_COLOR, 0.25)};
  cursor: pointer;
  background: white;
  border-radius: 4px;
  padding: 7px 7px 4px 7px;
`;

const CenterBlock = styled.div`
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
`;

const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  &>*+* { margin-top: 0.5em; }
  text-align: center;
  & > .title {
    color: ${BASE_COLOR};
    font-weight: bold;
    font-size: 1.2em;
  }
`;

const LegendBox = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid ${opacify(BASE_COLOR, 0.15)};
  box-shadow: 0 0 2px ${opacify(BASE_COLOR, 0.25)};
  & a, a:visited {
    color: ${BASE_COLOR};
  }
`;

const Loading = styled(LegendBox)({
  display: 'flex',
  alignSelf: 'center',
  fontSize: '1 rem'
});


const TotalCount = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  //color: rgb(270, 120, 124);
  color: ${BASE_COLOR};
  //text-shadow: 1px 1px 2px #F04E23,-1px 1px 2px #F04E23,-1px -1px 2px #F04E23,1px -1px 2px #F04E23;
`;

const Timestamp = styled.div`
  font-size: 0.6rem;
  height: 1em;
`;

const Credits = styled.div`
  font-size: 0.6rem;
  margin-top: 1em;
  line-height: 1.5em;
`;

const TopLegend = styled(LegendBox)`
  max-width: 230px;
  right: 20px;
`;

const MODAL_TITLE_HEIGHT = 80;
const ModalTitle = styled.div`
  box-sizing: border-box;
  padding-left: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  &>*+*{ margin-top:10px; }
  height: ${MODAL_TITLE_HEIGHT}px;
`;

const CityNavButtonsArea = styled.div`
  display: flex;
  &>*+*{margin-left: 5px;}
`;
const ModalContent = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - ${MODAL_TITLE_HEIGHT}px);
  overflow: auto;
`;

const BottomLegend = styled(LegendBox)`
  color: ${TEXT_COLOR};
  text-align: center;
  max-width: 140px;
  & > *+* { margin-top: 5px; }
`;

const getBoxStyle = () => `
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  font-size: 11px;
  box-shadow: 0 0 5px ${opacify(BASE_COLOR, 0.5)}; 
`;

export interface AbsoluteProps {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
}


export const Absolute = styled.div<AbsoluteProps>(
  ({ top, left, right, bottom }: AbsoluteProps) => `
  position: absolute;
  ${top != null ? `top: ${top}px;` : ''}
  ${left != null ? `left: ${left}px;` : ''}
  ${right != null ? `right: ${right}px;` : ''}
  ${bottom != null ? `bottom: ${bottom}px;` : ''}
`
);


export const Box = styled(Absolute)<{}>(getBoxStyle);

const TooltipBox = styled(Box)({
  pointerEvents: 'none',
  color: TEXT_COLOR,
  padding: 10,
  maxWidth: 120,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  '&>.location':{
    // textShadow: `
    // 1px -1px 2px ${TEXT_COLOR},
    // -1px 1px 2px ${TEXT_COLOR},
    // -1px -1px 2px ${TEXT_COLOR},
    // 1px 1px 2px ${TEXT_COLOR}
    // `,
    // color: '#fff'
    fontWeight: 'bold',
  },
  '&>.number':{
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '12pt',
    color: BASE_COLOR
  },
  '&>* + *': {
    marginTop: 5,
  },
});


const CONTROLLER_OPTIONS = {
  type: MapController,
  doubleClickZoom: false,
  dragRotate: false,
  touchRotate: false,
  minZoom: 0,
  maxZoom: 15,
};


function getInitialViewport(bbox: [number, number, number, number]) {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const {
    center: [longitude, latitude],
    zoom,
  } = viewport(bbox, [width, height], undefined, undefined, 512, true);
  return {
    width,
    height,
    longitude,
    latitude,
    zoom,
    minZoom: MIN_ZOOM_LEVEL,
    maxZoom: MAX_ZOOM_LEVEL,
    minPitch: MIN_PITCH,
    maxPitch: MAX_PITCH,
    bearing: 0,
    pitch: 0,
    altitude: 1.5,
  };
}


const INITIAL_VIEWSTATE = getInitialViewport([16.4,41.2,170.0,70]);



const MapOuter = styled.div({
  display: 'flex',
  flexGrow: 1,
  position: 'relative',
});

const MouseMoveContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0; left: 0;
`;

const DeckGLOuter = styled.div<{
  darkMode: boolean;
  baseMapOpacity: number;
  cursor: 'crosshair' | 'pointer' | undefined;
}>(
  ({ cursor, baseMapOpacity, darkMode }) => `
  #deckgl-wrapper {
    background: ${BACKGROUND_COLOR};
  }
  & #deckgl-overlay {
    // mix-blend-mode: ${darkMode ? 'screen' : 'multiply'};
  }
  & .mapboxgl-map {
    opacity: ${baseMapOpacity}
  }
  ${cursor != null ? `& #deckgl-wrapper { cursor: ${cursor} !important; }` : ''},
`
);


export type DateRecord = {
  date: string;
  sheet: string;
  lastUpdated: string;
  latest: string;
}

export type Location = {
  name: string;
  lat: number;
  lon: number;
}

export type CountItem = {
  name: string;
  count: number;
}

export type Item = Location & CountItem;

export interface ListRecord {
  name: string;
  city: string;
  region: string;
  date: Date;
  role: string;
  roleComment: string;
  persecutionTypes: string[];
  typeComment: string;
  description: string;
  source: string;
}



function App() {
  const [viewport, setViewport] = useState<ViewState>(INITIAL_VIEWSTATE);
  const [ tooltip, setTooltip] = useState<any>();
  const [isListOpen, setListOpen] = useState(false);
  const [listItems, setListItems] = useState<ListRecord[]>();
  const [selectedCity, setSelectedCity] = useState<string>();
  // const [ hoverItem, setHoverItem ] = useState<Item>();
  const handleViewStateChange = ({ viewState }: ViewStateChangeInfo) => {
    setViewport(viewState);
    // setSelectedLocation(null);
    setTooltip(undefined);
  };
  const windowSize = useWindowSize();
  useEffect(() => {
    if (windowSize)  // not sure why this doesn't fire from react-map-gl
    setViewport({
      ...viewport,
      ...windowSize,
    });
  },[windowSize?.width, windowSize?.height]);
  const fetchShapes = useFetch(`/shapes.topo.json`);
  const geoJsonFeatures = useMemo(() => {
    const topology = fetchShapes.data as Topology<any>;
    if (topology) {
      return feature<any>(topology, topology.objects['zones']) as any as FeatureCollection;
    }
    return undefined;
  }, [fetchShapes.data]);

  const layers: any[] = [];
  const fetchList = useFetch<ListRecord[]>(DATA_URL, {
    formatter: async (response) => {
      const parseDate = timeParse('%m/%e/%Y');
      // (d: string) => {
      //   const parts = d.split('/');
      //   if (parts.length < 3) return null;
      //   return new Date(+parts[2], +parts[0]+1, +parts[1]);
      // }
      const list = await response.text().then((text) =>
        csvParse(text, rawRow => {
          const obj: any = {};
          for (const [k,v] of Object.entries(LIST_RECORD_COLUMNS)) {
            const text = rawRow[v];
            switch (k) {
              case 'persecutionTypes':
                obj[k] = text ? csvParseRows(text)[0] : [];
                break;
              case 'date':
                obj[k] = parseDate(rawRow[LIST_RECORD_COLUMNS.date]!);
                break;
              default:
                obj[k] = text;
            }
          }
          return obj;
        }) as any as ListRecord[]
      );
      list.sort((a,b)=>descending(a.date,b.date))
      return list;
    }
  });

  const recordsByCity = useMemo(() => {
    if (!fetchList.data) return undefined;
    return fetchList.data
      .reduce((m, d) => {
        let list = m.get(d.city);
        if (!list) {
          list = new Array<ListRecord>();
          m.set(d.city, list);
        }
        list.push(d);
        return m;
      }, new Map<string, Array<ListRecord>>());
  }, [fetchList.data]);

  const cities = useMemo(() => {
    if (!recordsByCity) return undefined;
    return Array.from(recordsByCity.keys());
  }, [recordsByCity]);

  const fetchLocations = useFetch<Location[]>(CITIES_URL, {
    formatter: (response) =>
      response.text().then((text) =>
        csvParse(text, (row: any) => ({
          name: row['Name'],
          lat: +row['Широта'],
          lon: +row['Долгота'],
        })) as any as Location[]
      ),
  });

  const countsData = useMemo(() => {
    if (!recordsByCity || !fetchLocations.data) return undefined;
    const locationsByName = fetchLocations.data
      .reduce((m,d) => {m.set(d.name,d); return m;}, new Map<string,Location>());
    const data: Item[] = [];
    for (const city of recordsByCity.keys()) {
      const loc = locationsByName.get(city);
      if (loc) {
        data.push({
          ...loc,
          count: recordsByCity.get(city)!.length
        });
      } else {
        console.warn(`No location by name '${city}'`)
      }
    }
    data.sort((a,b)=> descending(a.count, b.count));
    return data;
  }, [recordsByCity, fetchLocations.data]);

  const maxCount = useMemo(() => {
    if (!countsData) return undefined;
    return max(countsData, d=> d.count)
  }, [countsData]);

  const totalCount = useMemo(() => {
    if (!fetchList.data) return undefined;
    return fetchList.data.length;
  }, [fetchList.data]);

  const sizeScale = useMemo(() => {
    if (!maxCount) return undefined;
    return scaleSqrt().range([5,20]).domain([1,maxCount]);
  }, [maxCount]);


  const mercator = useMemo(() => new WebMercatorViewport(viewport), [viewport]);

  const spatialIndex = useMemo(() => {
    if (countsData && countsData.length > 0) {
      const index = new Flatbush(countsData.length);
      for (const d of countsData) {
        const [x,y]= mercator.project([d.lon, d.lat]);
        index.add(x,y,x,y);
      }
      index.finish();
      return index;
    }
    return undefined;
  }, [countsData, mercator]);

  if (geoJsonFeatures != null) {
    layers.push(
      new GeoJsonLayer({
        id: 'shapes',
        data: geoJsonFeatures,
        stroked: true,
        filled: true,
        opacity: 1.0,
        lineWidthUnits: 'pixels',
        getLineWidth: 0.5,
        getLineColor: SHAPE_LINE_COLOR,
        getFillColor: SHAPE_FILL_COLOR,
        pickable: false,
      }),
    );
  }

  const setTooltipItem = (d: Item | undefined) => {
    if (!d || !sizeScale) {
      setTooltip(undefined);
      return;
    }
    if (tooltip?.object === d) return;
    const [x,y]= mercator.project([d.lon, d.lat]);
    const r = sizeScale(d.count);
    // const dx = Math.cos(Math.PI/4) * r + 2;
    // const dy = Math.sin(Math.PI/4) * r + 2;
    const dx = r + 4;
    const dy = r + 4;
    setTooltip({
        object: d,
        x: x + dx,
        y: y + dy,
        virtualReference: {
          getBoundingClientRect() {
            return {
              top: y - dy,
              left: x - dx,
              bottom: y + dy,
              right: x + dx,
              width: dx * 2,
              height: dy * 2,
            };
          }
        },
      }
    );
  };

  if (countsData && sizeScale) {
    layers.push(
      new ScatterplotLayer({
        id: 'scatterplot-layer',
        data: countsData,
        pickable: true,
        // opacity: 0.8,
        stroked: true,
        filled: true,
        radiusUnits: 'pixels',
        // radiusMinPixels: 1,
        // radiusMaxPixels: 100,
        lineWidthMinPixels: 1,
        lineWidthUnits: 'pixels',
        getPosition: (d: Item) => [d.lon, d.lat],
        getRadius: (d: Item) => sizeScale(d.count),
        lineWidthScale: 2,
        getLineColor: (d: Item) => {
          if (tooltip && tooltip.object === d) {
            return CIRCLE_LINE_COLOR__HIGHLIGHTED;
          }
          return CIRCLE_LINE_COLOR;
        },
        getLineWidth: (d: Item) => {
          if (tooltip && tooltip.object === d) {
            return 1;
          }
          return 0.25;
        },
        getFillColor: CIRCLE_FILL_COLOR,
        // getFillColor: (d: Item) => {
        //   if (tooltip) {
        //     if (tooltip.object !== d) {
        //       return CIRCLE_FILL_COLOR__MUTED;
        //     }
        //   }
        //   return CIRCLE_FILL_COLOR;
        // },
        // autoHighlight: true,
        // highlightColor: [65, 158, 235],
        onHover: (info: any) => {
          setTooltipItem(info ? info.object : undefined);
        },
        updateTriggers: {
          // getFillColor: [tooltip]
          getLineColor: [tooltip],
          getLineWidth: [tooltip]
        }
      })
    );
  }

  const handleMouseMove = (evt: MouseEvent) => {
    let item: Item | undefined = undefined;
    if (spatialIndex && countsData && sizeScale) {
      const found = spatialIndex.neighbors(
        evt.clientX, evt.clientY, 1, 50
      );
      if (found.length > 0) {
        item = countsData[found[0]];
        // setHoverItem(item);
      }
    }
    setTooltipItem(item);
  }

  const [popperElement, setPopperElement] = React.useState(null);
  // const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(tooltip?.virtualReference, popperElement,
    // { modifiers: [{ name: 'arrow', options: { element: arrowElement } }],}
  );

  const outerRef = useRef<HTMLDivElement>(null);
  const isEmbedded = useMemo(() => {
    try {
      return window.self !== window.top;
    } catch (err) {}
    return false;
  }, []);

  // const [isFullScreen, setFullScreen] = useState(false);
  // const handleFullScreen = () => {
  //   const outer = outerRef.current;
  //   if (outer) {
  //     if (isFullScreen) {
  //       // @ts-ignore
  //       if (document.exitFullscreen) {
  //         document.exitFullscreen().then(() => setFullScreen(false));
  //       }
  //     } else {
  //       if (outer.requestFullscreen) {
  //         outer.requestFullscreen().then(() => setFullScreen(true));;
  //       }
  //     }
  //   }
  // };

  function handleSetSelectedCity(name: string) {
    if (!recordsByCity) return;
    const cityItems = recordsByCity.get(name);
    if (cityItems) {
      setListOpen(true);
      setListItems(cityItems);
      setSelectedCity(name);
    }
  }

  const handleClick = () => {
    if (tooltip && recordsByCity) {
      handleSetSelectedCity(tooltip.object.name);
    }
  };

  const handleListClose = () => {
      setListOpen(false);
      setListItems(undefined);
      setSelectedCity(undefined);
  }

  const handlePrevCity = () => {
    if (!selectedCity || !cities) return;
    const idx = cities?.indexOf(selectedCity);
    const next = (idx >= 0 ? idx - 1 : cities.length - 1);
    handleSetSelectedCity(cities[next]);
  }

  const handleNextCity = () => {
    if (!selectedCity || !cities) return;
    const idx = cities?.indexOf(selectedCity);
    const next = (idx < cities.length - 1 ? idx + 1 : 0);
    handleSetSelectedCity(cities[next]);
  }

  const handleShowList = () => {
    if (!cities) return;
    handleSetSelectedCity(cities[0]);
  }

  return (
    <Outer ref={outerRef}>
      <MapOuter
      >
        <MapContainer>
          <DeckGLOuter
            darkMode={DARK_MODE}
            baseMapOpacity={100}
            cursor="pointer"
            onClick={handleClick}
          >
            <MouseMoveContainer
              // @ts-ignore
              onMouseMove={handleMouseMove}
              onMouseLeave={() => setTooltip(undefined)}
            >
              <DeckGL
                repeat={true}
                controller={CONTROLLER_OPTIONS}
                layers={layers}
                onViewStateChange={handleViewStateChange}
                views={[new MapView({id: 'map', repeat: true})]}
                viewState={viewport}
                ContextProvider={MapContext.Provider}
                // parameters={{
                //   clearColor: colorAsRgba('#fff')
                // }}
              >
                <LabelsOverlay
                  sizeScale={sizeScale}
                  countsData={countsData}
                  viewport={viewport}
                />
                {SHOW_BASE_MAP && <StaticMap
                  mapboxApiAccessToken={accessToken}
                  mapStyle={mapboxMapStyle}
                  width="100%"
                  height="100%"
                />
                }
              </DeckGL>
            </MouseMoveContainer>
            {tooltip?.object &&
            createPortal(<TooltipBox
                // @ts-ignore
                ref={setPopperElement}
                style={styles.popper} {...attributes.popper}
                // top={tooltip.y} left={tooltip.x}
                className="tooltip"
              >
                <div className="location">
                  {tooltip.object.name}
                </div>
                <div className="number">
                  {tooltip.object.count}
                </div>
                <div style={{fontSize:'8px'}}>
                  Кликните, чтобы увидеть подробный список
                </div>
                {/*<div className="arrow"*/}
                {/*  // @ts-ignore*/}
                {/*     ref={setArrowElement} style={styles.arrow} />*/}
              </TooltipBox>,
              document.body)
            }

            {(fetchList.isLoading || fetchLocations.isLoading)
              ? <CenterBlock><Loading>
                {/*<div>Загрузка данных…</div>*/}
                <SpinningCircles/>
              </Loading></CenterBlock>
              : null
            }

            <Absolute top={isEmbedded ? 5 : 15} left={isEmbedded ? 5 : 15}>
            <TopLegend>
              <TitleArea>
                <div className="title">Преследование</div>
                <div className="subtitle">сторонников Алексея Навального</div>
                <div>с 16.4.2021</div>
                <Button
                  onClick={handleShowList}
                >Показать список</Button>
              </TitleArea>
            </TopLegend>
            </Absolute>

            {countsData && totalCount &&
            <Absolute bottom={isEmbedded ? 5 : 15} left={isEmbedded ? 5 : 15}>
              <BottomLegend>
                <div>Всего</div>
                <TotalCount>{totalCount}</TotalCount>
                <div>{2<=(totalCount%10) && (totalCount%10)<=4 ? 'человека' : 'человек'}</div>
                <Credits>
                  Данные: <a href="https://ovdinfo.org/" rel="noreferrer" target="_blank">ОВД-Инфо</a>
                </Credits>
              </BottomLegend>
            </Absolute>
            }

            {isEmbedded &&
            <Absolute bottom={15} right={isEmbedded ? 5 : 15}>
              <FullscreenButton
                title="Во весь экран"
                href="/"
                rel="noreferrer" target="_blank"
              ><img src={fullscreenIcon} width={16} height={16}/></FullscreenButton>
            </Absolute>}

          </DeckGLOuter>
        </MapContainer>
      </MapOuter>

      {recordsByCity && isListOpen &&
      <Modal
        width="90%"
        height="90%"
        maxWidth="610px"
        onClose={handleListClose}
      >
        <ModalTitle>
          {selectedCity && <h3>{selectedCity}</h3>}
          <CityNavButtonsArea>
            <Button
              title="Предыдущий город"
              onClick={handlePrevCity}
            >← Предыдущий город</Button>
            <Button
              title="Следующий город"
              onClick={handleNextCity}
            >Следующий город →</Button>
          </CityNavButtonsArea>
        </ModalTitle>
        <ModalContent>
          <List
            selectedCity={selectedCity}
            listItems={listItems}
          />
        </ModalContent>
      </Modal>}
    </Outer>
  );
}

export default App;
