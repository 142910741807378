import {color as d3color, hcl, rgb} from 'd3-color';
import {timeFormat} from 'd3-time-format';

export const BASE_COLOR = '#F04E23';
export const TEXT_COLOR = '#2D2D2D';
export const BACKGROUND_COLOR = '#DBD9D8';

export const SHAPE_LINE_COLOR = [150, 100, 100];
export const SHAPE_FILL_COLOR = colorAsRgba('#FAEBE5ff');
export const CIRCLE_LINE_COLOR = [255, 255, 255];
export const CIRCLE_LINE_COLOR__HIGHLIGHTED = colorAsRgba(
  // @ts-ignore
  hcl(BASE_COLOR).darker()
);
export const CIRCLE_FILL_COLOR = colorAsRgba(BASE_COLOR);
const FALLBACK_COLOR_RGBA: RGBA = [255, 255, 255, 255];

export const FORMAT_DATE = timeFormat('%d.%m.%Y');


export const LIST_RECORD_COLUMNS = {
  name: 'Преследуемый',
  city: 'Город',
  ignore: 'Не для карты',
  region: 'Регион',
  date: 'Дата эпизода политпрессинга',
  role: 'Кем является?',
  roleComment: 'Комментарий, если в «Кто является» — «Другое»',
  persecutionTypes: 'Вид политпрессинга',
  typeComment: 'Комментарий к виду политпрессинга',
  description: 'Описание кейса (что произошло)',
  source: 'Ссылка на источник',
};

export function opacify(color: string, v: number) {
  const c = rgb(color);
  c.opacity *= v;
  return c.toString();
}

export type RGBA = [number, number, number, number];

export function opacityFloatToInteger(opacity: number): number {
  return Math.round(opacity * 255);
}

export function colorAsRgba(color: string): RGBA {
  const col = d3color(color);
  if (!col) {
    console.warn('Invalid color: ', color);
    return FALLBACK_COLOR_RGBA;
  }
  const rgbColor = rgb(color);
  return [Math.floor(rgbColor.r), Math.floor(rgbColor.g), Math.floor(rgbColor.b), opacityFloatToInteger(col.opacity)];
}
