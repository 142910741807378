import React from 'react';
import {ListRecord} from './App';
import styled from '@emotion/styled';
import {BASE_COLOR, FORMAT_DATE, opacify, TEXT_COLOR} from './constants';

const Outer = styled.div`
  color: ${TEXT_COLOR};
  a {  color: ${BASE_COLOR}; }
  padding: 20px;
`;

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  &>*+*{ margin-top: 50px; }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  &>*+*{ margin-top: 10px; }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  &>*+*{ margin-left: 20px; }
  align-items: center;
  justify-items: center;
`;

const Name = styled.span`
  font-weight: bold;
  font-size: 13px;
`;

const Date = styled.span`
  font-size: 10px;
`;

const Desc = styled.div`
  //font-size: 10px;
  padding: 5px 15px;
  border-left: 2px solid ${BASE_COLOR};
  border-right: 2px solid ${BASE_COLOR};
  background: ${opacify(BASE_COLOR, 0x10/255)};
`;

const BadgeArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  &>*+*{ margin-left: 4px; }
  //display: grid;
  //grid-gap: 5px;
  //grid-template-columns: repeat(3, min-content);
  //align-items: flex-start;
`;

const Badge = styled.div`
  display: inline-block;
  font-size: 9px;
  background-color: ${opacify(BASE_COLOR, 210/255)};
  color: white;
  border-radius: 4px;
  padding: 2px 5px;
  margin-bottom: 3px;
`;

export interface Props {
  selectedCity: string | undefined;
  listItems: ListRecord[] | undefined;
}

const List: React.FC<Props> = (props) => {
  const {selectedCity, listItems} = props;
  return (
    <Outer>
      <Rows>
      {listItems?.map((d,i) =>
        <Column key={i}>
          <Row>
            <Name>{d.name}</Name>
            <Date>{FORMAT_DATE(d.date)}</Date>
          </Row>
          {d.persecutionTypes &&
          <BadgeArea>{
            (d.persecutionTypes || d.typeComment).map((type, ti) =>
              <Badge key={ti}>{type}</Badge>
            )
          }</BadgeArea>}
          {d.role && <div>{d.role}</div>}
          {d.roleComment && <div>{d.roleComment}</div>}
          {d.description && <Desc>{d.description}</Desc>}
          {d.source &&
          <div>
            <a href={d.source} target="_blank" rel="noopener noreferrer">Источник</a>
          </div>}
        </Column>
      )}
      </Rows>
    </Outer>
  );
};

export default List;